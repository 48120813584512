import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./live-call-routing@gen.scss";

const GeneratedProductFeaturesLiveCallRouting = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          ライブコールルーティング - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="オンコールのレスポンダ―にすぐに連絡を取る"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="オンコールのレスポンダ―にすぐに連絡を取る"
        />

        <meta
          property="og:title"
          content="ライブコールルーティング - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="オンコールのレスポンダ―にすぐに連絡を取る"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/live-call-routing/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/live-call-routing/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="ライブコールルーティング"
        overview=""
      />

      <div className="lcrWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            オンコールのレスポンダ―にすぐに連絡を取る
          </h2>
          <p className="center-para">
            電話番号を呼び出すだけでオンコールチームにすぐに連絡を取りインシデントを報告できることで、インシデントのより迅速な受信と解決を確実にします。インバウンドコールは、PagerDutyで対応している重要なアプリやサービスに同一の通話スケジュールとエスカレーションポリシーでルーティングされるため、誰でもすぐに適切なレスポンダーに連絡を取ることができます。
          </p>
        </div>
        <div className="max-930px-img">
          <img
            className="object-contain"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/lcr_8eba083a9d.jpg"
            alt="オンコールのレスポンダ―にすぐに連絡を取る"
          />
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">ライブコールルーティングに関する詳細</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>リアルタイムでやり取り</p>
            </div>
            <p>
              電話をかけるだけでオンコールのスタッフと直接やり取りできるため、オンコールスケジュールを調べる手間を回避してMTTAとMTTRを大幅に低減します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>自動エスカレーション</p>
            </div>
            <p>
              コールは、PagerDutyでご利用のすべてのアラートに対して同一のグローバルオンコールスケジュールとエスカレーションルールを使用して転送され、確実にレスポンダーが問題に対処するようにしています。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>国際番号の設定</p>
            </div>
            <p>
              PagerDutyを使用すると簡単に国際番号を設定でき、オンコールチームにつながるかどうかの不安を解消します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                コールによる
                <br />
                インシデントの発行
              </p>
            </div>
            <p>
              顧客やユーザーがボイスメールを残すと、自動的にインシデントとして扱われます。音声記録は、インシデント解決に活用できトレーニングのサポートにも役立ちます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>特定のチームに連絡</p>
            </div>
            <p>
              1つの番号で複数のサービスやチームにつながることができます。お客様は、内線番号を押すだけで、まさに必要な相手に連絡を取ることができるのです
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesLiveCallRouting;
